import React from "react";
import { API_UPLOADS_URL } from "../../../constants/paths";
import ReactPaginate from "react-paginate";
import ReactHtmlParser from "html-react-parser";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import { format_amount } from "../../../helpers/helpers";

const Trainers = ({ trainers }) => {
  //getting start_date and End_date from the API and show the formate in 27+29 Jan
  const getDate = (date) => {
    let date1 = new Date(date);
    let date2 = new Date(date1);
    date2.setDate(date2.getDate() + 1);
    let month1 = date1.toLocaleString("default", { month: "short" });
    let month2 = date2.toLocaleString("default", { month: "short" });
    let day1 = date1.getDate();
    let day2 = date2.getDate();
    return day1 + " - " + day2 + " " + month1;
  };

  return (
    <>
      {trainers?.length > 0 ? (
        <div className="flex">
          {trainers.map((trainer, index) => (
            <div className="col" key={index}>
              <div className="inner">
                <div className="panel_body">
                  <div className="badge_training">
                    <span>
                      {localStorage.getItem("site_lang") === "eng"
                        ? trainer.badge_text
                        : trainer.fr_badge_text}
                    </span>
                  </div>
                  <a href={trainer.link} target="_blank">
                    <div className="image">
                      <ImageControl
                        isThumb={true}
                        folder="training_program"
                        src={trainer.image}
                      />
                    </div>
                  </a>
                </div>
                <div className="penal_heading">
                  <h4>
                    {localStorage.getItem("site_lang") === "eng"
                      ? trainer.heading
                      : trainer.fr_heading}
                  </h4>
                  <p>
                    {localStorage.getItem("site_lang") === "eng"
                      ? trainer.sub_heading
                      : trainer.fr_sub_heading}
                  </p>
                </div>
                <div className="panel_footer">
                  <div className="summery">
                    <p>
                      {ReactHtmlParser(
                        localStorage.getItem("site_lang") === "eng"
                          ? trainer.detail
                          : trainer.fr_detail
                      )}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="listing_public">
                      <div className="_innerlst">
                        <div className="panel-heading">
                          {localStorage.getItem("site_lang") === "eng"
                            ? "PUBLIC"
                            : "PUBLIC"}
                        </div>
                        <div className="flex">
                          <div className="date">
                            <div className="rolodex-widget">
                              <div className="rings">
                                <span className="left-ring" />
                                <span className="right-ring" />
                              </div>
                              <div className="sheet">
                                <span>{getDate(trainer.start_date)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="duration">
                            <ul>
                              <li>
                                <strong>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Duration"
                                    : "Durée"}{" "}
                                  :
                                </strong>
                                <span>7h00</span>
                              </li>
                              <li>
                                <strong>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Level"
                                    : "Niveau"}{" "}
                                  :
                                </strong>
                                <span>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Beginner-Intermediate"
                                    : "Débutant-Intermédiaire"}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="_price">
                            {format_amount(trainer.price)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="listing_private">
                      <div className="_innerlst">
                        <div className="panel-heading">
                          {localStorage.getItem("site_lang") === "eng"
                            ? "Private"
                            : "Privé"}
                        </div>
                        <p>
                          {localStorage.getItem("site_lang") === "eng"
                            ? "100% customized to your business needs"
                            : "100% personnalisé selon vos besoins d'entreprise"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="col">
          <div className="inner">
            <div className="no_job">
              <h4>
                {localStorage.getItem("site_lang") === "eng"
                  ? "No Training Program Found"
                  : "Aucun programme de formation trouvé"}
              </h4>
              <p>
                <small>
                  {localStorage.getItem("site_lang") === "eng"
                    ? "Sorry, we couldn't find any Program"
                    : "Désolé, nous n'avons trouvé aucun programme"}
                  .
                </small>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Trainers;
