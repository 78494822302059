import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  DELETE_NOTIFICATIONS,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  notifications: [],
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
        content: {},
        notifications: [],
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: action.payload,
        notifications: action.payload.notifications,
      };
    case FETCH_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        notifications: [],
        error: action.payload,
      };
    case DELETE_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
        content: {},
        notifications: [],
      };
    case DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: action.payload,
        notifications: action.payload.notifications,
      };
    case DELETE_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        notifications: [],
        error: action.payload,
      };
    default:
      return state;
  }
}
