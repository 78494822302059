import React, { useState, useEffect } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobs } from "../../../../states/actions/employerJobs";
import { deleteJob } from "../../../../states/actions/employerJobs";
import { API_UPLOADS_URL } from "../../../../constants/paths";
import moment from "moment/moment";
import { ToastContainer } from "react-toastify";
import ReactHtmlParser from "html-react-parser";
import JobPush from "./Job-push";
import { jobPush } from "../../../../states/actions/jobPush";
import LoadingScreen from "../../../common/LoadingScreen";
import ImageControl from "../../../common/ImageControl";
import { format_amount } from "../../../../helpers/helpers";
import { fetchSiteSettings } from "../../../../states/actions/fetchSiteSettings";

const MyJobs = () => {
  const [pushPopup, setPushPopup] = useState(false);
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.employerJobs.content.jobs);
  const pricing_plan = useSelector(
    (state) => state.employerJobs.content.pricing_plan
  );
  // const total_saved_jobs = useSelector((state) => state.employerJobs.content.total_saved_jobs);
  // const total_saved_jobs_allowed = useSelector((state) => state.employerJobs.content.total_saved_jobs_allowed);
  const remaining_post_jobs = useSelector(
    (state) => state.employerJobs.content.remaining_post_jobs
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isLoading = useSelector((state) => state.employerJobs.isLoading);
  const [pushJobId, setPushJobId] = useState(null);

  const TogglePush = (id) => {
    setPushJobId(id);
    setPushPopup(!pushPopup);
  };
  useEffect(() => {
    dispatch(fetchJobs());
    dispatch(fetchSiteSettings());
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteJob(id));
  };

  const handlePush = () => {
    setPushPopup(!pushPopup);
    dispatch(jobPush(pushJobId));
  };
  console.log(jobs);

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <HeaderLogged site_settings={siteSettings} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /
                        {localStorage.getItem("site_lang") === "eng"
                          ? "My Jobs"
                          : "Mes emplois"}
                      </span>
                    </h3>
                    <div className="bTn">
                      <Link to="/employer/post-job" className="webBtn">
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Post New Job"
                          : "Publier un nouvel emploi"}
                      </Link>
                    </div>
                  </div>

                  <div>
                    {localStorage.getItem("site_lang") === "eng"
                      ? "Total jobs posting left"
                      : "Nombre total d'offres d'emploi restantes"}
                    :{" "}
                    <span className="jobs-left">
                      {" "}
                      {remaining_post_jobs ? remaining_post_jobs : 0}{" "}
                    </span>
                  </div>
                  <div className="dash_body">
                    <div className="flex job_flex applied_job_flex">
                      {jobs?.length > 0 ? (
                        jobs.map((job) => (
                          <div className="col">
                            <div className="inner">
                              <div className="dropDown dash_actions absolute_action">
                                <span className="dropBtn">
                                  <i className="fi fi-rr-menu-dots"></i>
                                </span>
                                <div className="dropCnt">
                                  <ul className="dropLst">
                                    <li>
                                      <Link
                                        to={`/employer/edit-job/${job.id}`}
                                        className="webBtn labelBtn blue-color"
                                      >
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? "Edit"
                                          : "Modifier"}
                                      </Link>
                                    </li>
                                    <li>
                                      <div
                                        onClick={() => TogglePush(job.id)}
                                        className="webBtn labelBtn blue-color"
                                      >
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? "Push"
                                          : "Pousser"}
                                      </div>
                                    </li>
                                    <li>
                                      <Link
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              localStorage.getItem(
                                                "site_lang"
                                              ) === "eng"
                                                ? "Are you sure to delete all notifications?"
                                                : "Êtes-vous sûr de supprimer toutes les notifications ?"
                                            )
                                          )
                                            handleDelete(job.id);
                                        }}
                                        className="webBtn labelBtn red-color"
                                      >
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? "Delete"
                                          : "Supprimer"}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="head_job">
                                <div className="img_ico">
                                  <ImageControl
                                    isThumb={true}
                                    folder="jobs"
                                    src={job.company_logo}
                                  />
                                </div>
                                <div className="cntnt">
                                  <div className="featured_lbl">
                                    {job.company_name}
                                  </div>
                                  <h4>
                                    <Link to={`/job-details/${job.id}`}>
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? job.title
                                        : job.fr_title}
                                    </Link>
                                  </h4>
                                  <ul>
                                    <li>
                                      <i className="fi fi-rr-marker" />{" "}
                                      <span>{job.city}</span>
                                    </li>
                                    <li>
                                      <i className="fi fi-rr-briefcase" />{" "}
                                      <span>
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? job.job_type_name
                                          : job.job_type_name_fr}
                                      </span>
                                    </li>
                                    <li>
                                      <i className="fi fi-rr-clock-two" />{" "}
                                      <span>
                                        {moment(job.created_date).fromNow()}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="job_bdy">
                                <p>
                                  {job.description &&
                                    ReactHtmlParser(
                                      localStorage.getItem("site_lang") ===
                                        "eng"
                                        ? job?.description
                                        : job?.fr_description
                                    )}
                                  {/* {ReactHtmlParser(job?.description)} */}

                                  {/* {job.description} */}
                                </p>
                                <div className="skils">
                                  {job.tags?.split(",").map((tag, index) => (
                                    <span key={index}>{tag}</span>
                                  ))}
                                </div>
                              </div>
                              <div className="job_footer">
                                <div className="applicant_lst_flex">
                                  <ul>
                                    {job.applicants.length > 0 &&
                                      job.applicants
                                        .slice(0, 3)
                                        .map((applicant) => (
                                          <li>
                                            <ImageControl
                                              isThumb={true}
                                              folder="members"
                                              src={applicant.mem_image}
                                            />
                                          </li>
                                        ))}
                                  </ul>
                                  {job.applicants.length > 0 ? (
                                    <Link
                                      to={`/employer/applicants/${job.id}`}
                                      className="view_all"
                                    >
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "View All Applicant"
                                        : "Afficher tous les candidats"}
                                    </Link>
                                  ) : (
                                    <span className="view_all">
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "No Applicants"
                                        : "Aucun candidat"}
                                    </span>
                                  )}
                                </div>
                                <div>
                                  <div className="job_price">
                                    {format_amount(job.min_salary)} -
                                    {format_amount(job.max_salary)}
                                    <span></span>
                                  </div>
                                  <Link
                                    to={`/job-details/${job.id}`}
                                    className="webBtn mdBtn"
                                  >
                                    {localStorage.getItem("site_lang") === "eng"
                                      ? "View Details"
                                      : "Afficher les détails"}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col">
                          <div className="inner">
                            <div className="no_job">
                              <h4 className="text-center">
                                {isLoading
                                  ? localStorage.getItem("site_lang") === "eng"
                                    ? "Data Fetching..."
                                    : "Récupération de données..."
                                  : localStorage.getItem("site_lang") === "eng"
                                  ? "No Jobs Found"
                                  : "Aucun emploi trouvé"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <JobPush
              pushPopup={pushPopup}
              togglePush={TogglePush}
              noOfPush={pricing_plan?.no_of_push}
              handlePush={handlePush}
            />
          </main>
        </>
      )}
    </>
  );
};

export default MyJobs;
