import React, { useEffect, useState } from "react";
import Sidebar from "../../shared/Sidebar";
import HeaderLogged from "../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import LoadingScreen from "../../common/LoadingScreen";
import { fetchAllNotifications } from "../../../states/actions/fetchNotifications";
import { API_UPLOADS_URL } from "../../../constants/paths";
import moment from "moment/moment";

const CandidateNotification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.fetchNotifications.content.notifications
  );
  const isLoading = useSelector((state) => state.fetchNotifications.isLoading);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  useEffect(() => {
    dispatch(fetchAllNotifications());
    dispatch(fetchSiteSettings());
  }, []);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <HeaderLogged site_settings={siteSettings} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <Sidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /{" "}
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Notifications"
                          : "Notifications"}
                      </span>
                    </h3>
                  </div>
                  <div className="dash_body">
                    <div className="dash_heading_sec">
                      <h2>
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Notifications"
                          : "Notifications"}
                      </h2>
                    </div>
                    <div className="dash_blk_box notiBlk">
                      {notifications?.length > 0 ? (
                        notifications?.map((item, index) => (
                          <div className="inner">
                            <div className="ico">
                              {/* <img src="/images/1.png" alt="" /> */}
                              {item?.sender_pic?.trim() === "" ? (
                                <img src="/images/user.png" alt="" />
                              ) : (
                                <img
                                  src={`${API_UPLOADS_URL}/members/${item?.sender_pic}`}
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="txt">
                              <p>
                                {item?.txt}
                                {/* You have a new order request from Jennifer K.{" "}
                            <a
                              href="javascript:void(0)"
                              className="popBtn"
                              data-popup="request-detail"
                            >
                              click here
                            </a>{" "}
                            to view detail. */}
                              </p>
                              <span className="time">
                                {moment(item?.created_at).fromNow()}
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="inner">
                          <div className="txt">
                            <h4>
                              {localStorage.getItem("site_lang") === "eng"
                                ? "No Notifications Found!"
                                : "Aucune notification trouvée !"}
                            </h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default CandidateNotification;
