import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardSmallJob from "./DashboardSmallJob";

const DashboardJobsListing = ({ data, link }) => {
  // console.log(data, "here");

  const [pushPopup, setPushPopup] = useState(false);
  const TogglePush = () => {
    setPushPopup(!pushPopup);
  };

  return (
    <>
      <div className="flex job_flex job_flex_100">
        <DashboardSmallJob togglePush={TogglePush} data={data} link={link} />
      </div>
      <section
        className={
          pushPopup
            ? "popup small-popup push_popup active"
            : "popup small-popup push_popup"
        }
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={TogglePush}></div>
              <h4>
                {localStorage.getItem("site_lang") === "eng"
                  ? "Push your job on top"
                  : "Mettez votre travail au premier plan"}
              </h4>
              <p>
                {localStorage.getItem("site_lang") === "eng"
                  ? "According to your"
                  : "Selon votre"}{" "}
                <strong>
                  {localStorage.getItem("site_lang") === "eng"
                    ? "subscription plan"
                    : "plan d'abonnement"}
                </strong>{" "}
                {localStorage.getItem("site_lang") === "eng"
                  ? "you can push your job on top"
                  : "vous pouvez mettre votre travail au premier plan"}{" "}
                <strong>
                  {localStorage.getItem("site_lang") === "eng"
                    ? "3 time"
                    : "3 fois"}
                </strong>
                .
              </p>
              <br />
              <div className="text-center">
                <Link to="/" className="webBtn">
                  {localStorage.getItem("site_lang") === "eng"
                    ? "Push Job"
                    : "Pousser le travail"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardJobsListing;
