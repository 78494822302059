import React, { useEffect, useState } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import DashboardStatCard from "../../../common/DashboardStatCard";
import DashboardJobsListing from "../../../common/DashboardJobsListing";
import { fetchEmployerData } from "../../../../states/actions/employerData";
import { fetchSiteSettings } from "../../../../states/actions/fetchSiteSettings";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../../../common/LoadingScreen";

const EmployerDashboard = () => {
  const dispatch = useDispatch();
  const employer = useSelector((state) => state.employerData.content);
  const jobsApplication = useSelector(
    (state) => state.employerData.content.applicants
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );

  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const [applications, setApplications] = useState([]);
  const isLoading = useSelector((state) => state.employerData.isLoading);

  useEffect(() => {
    dispatch(fetchEmployerData());
    dispatch(fetchSiteSettings());
  }, []);

  useEffect(() => {
    if (jobsApplication) {
      let tempApplications = [];
      jobsApplication.forEach((job) => {
        tempApplications.push(job.job);
      });
      setApplications(tempApplications);
    }
  }, [jobsApplication]);
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <HeaderLogged site_settings={siteSettings} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Overview"
                          : "Aperçu"}
                      </span>
                    </h3>
                  </div>
                  <div className="dash_body">
                    <div className="tiles_blk flex">
                      <DashboardStatCard
                        title={
                          localStorage.getItem("site_lang") === "eng"
                            ? "My Jobs"
                            : "Mes emplois"
                        }
                        number={employer?.jobs?.length}
                        icon="briefcase.svg"
                        link={"/employer/my-jobs"}
                      />
                      {/* <DashboardStatCard
                        title="Send Offers"
                        number={15}
                        icon="document.svg"
                        link={""}
                      /> */}
                      <DashboardStatCard
                        title={
                          localStorage.getItem("site_lang") === "eng"
                            ? "Messages"
                            : "Messages"
                        }
                        number={20}
                        icon="chat.svg"
                        link={"/employer/chat"}
                      />
                      <DashboardStatCard
                        title={
                          localStorage.getItem("site_lang") === "eng"
                            ? "Notifications"
                            : "Notifications"
                        }
                        number={siteSettings?.notification}
                        icon="bell.svg"
                        link={"/employer/notification"}
                      />
                    </div>
                    <div className="dash_heading_sec">
                      <h2>
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Latest Candidate Applications"
                          : "Dernières candidatures"}
                      </h2>
                    </div>
                    <DashboardJobsListing
                      data={applications}
                      link={"/candidate-profile"}
                    />
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default EmployerDashboard;
