import React, { useState, useEffect } from "react";
import Sidebar from "../../../shared/Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSavedJobs,
  deleteSavedJob,
} from "../../../../states/actions/fetchSavedJobs";
import { API_UPLOADS_URL } from "../../../../constants/paths";
import moment from "moment/moment";
import { ToastContainer } from "react-toastify";
import ReactHtmlParser from "html-react-parser";
import LoadingScreen from "../../../common/LoadingScreen";
import ImageControl from "../../../common/ImageControl";
import { format_amount } from "../../../../helpers/helpers";

const SavedJobs = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.fetchSavedJobs.content.jobs);
  const siteSettings = useSelector(
    (state) => state.fetchSavedJobs.content.site_settings
  );
  const isLoading = useSelector((state) => state.fetchSavedJobs.isLoading);
  const isDeleting = useSelector((state) => state.fetchSavedJobs.isDeleting);

  useEffect(() => {
    dispatch(fetchSavedJobs());
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteSavedJob(id));
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <HeaderLogged site_settings={siteSettings} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <Sidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Saved Jobs"
                          : "Emplois enregistrés"}
                      </span>
                    </h3>
                  </div>
                  <div className="dash_body">
                    <div className="flex job_flex applied_job_flex">
                      {isDeleting ? (
                        <div className="col">
                          <div className="inner">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Deleting..."
                              : "Suppression..."}
                          </div>
                        </div>
                      ) : jobs?.length > 0 ? (
                        jobs.map((job) => (
                          <div className="col">
                            <div className="inner">
                              <div className="dropDown dash_actions absolute_action">
                                <span className="dropBtn">
                                  <i className="fi fi-rr-menu-dots"></i>
                                </span>
                                <div className="dropCnt">
                                  <ul className="dropLst">
                                    <li>
                                      <Link
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              localStorage.getItem(
                                                "site_lang"
                                              ) === "eng"
                                                ? "Are you sure you wish to delete this job?"
                                                : "Etes-vous sûr de vouloir supprimer ce travail ?"
                                            )
                                          )
                                            handleDelete(job.id);
                                        }}
                                        className="webBtn labelBtn red-color"
                                      >
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? "Delete"
                                          : "Supprimer"}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="head_job">
                                <div className="img_ico">
                                  <ImageControl
                                    isThumb={true}
                                    folder="jobs"
                                    src={job.company_logo}
                                  />
                                </div>
                                <div className="cntnt">
                                  <div className="featured_lbl">
                                    {job.company_name}
                                  </div>
                                  <h4>
                                    <Link to={`/job-details/${job.id}`}>
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? job.title
                                        : job.fr_title}
                                    </Link>
                                  </h4>
                                  <ul>
                                    <li>
                                      <i className="fi fi-rr-marker" />{" "}
                                      <span>{job.city}</span>
                                    </li>
                                    <li>
                                      <i className="fi fi-rr-briefcase" />{" "}
                                      <span>
                                        {localStorage.getItem("site_lang") ===
                                        "eng"
                                          ? job.job_type_eng_name
                                          : job.job_type_fr_name}
                                      </span>
                                    </li>
                                    <li>
                                      <i className="fi fi-rr-clock-two" />{" "}
                                      <span>
                                        {moment(job.created_date).fromNow()}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="job_bdy">
                                <p>
                                  {job.description &&
                                    ReactHtmlParser(
                                      localStorage.getItem("site_lang") ===
                                        "eng"
                                        ? job?.description
                                        : job?.fr_description
                                    )}
                                  {/* {ReactHtmlParser(job?.description)} */}

                                  {/* {job.description} */}
                                </p>
                                <div className="skils">
                                  {job.tags?.split(",").map((tag, index) => (
                                    <span key={index}>{tag}</span>
                                  ))}
                                </div>
                              </div>
                              <div className="job_footer">
                                <div className="applicant_lst_flex"></div>
                                <div>
                                  <div className="job_price">
                                    {format_amount(job.min_salary)} -
                                    {format_amount(job.max_salary)}
                                    <span></span>
                                  </div>
                                  <Link
                                    to={`/job-details/${job.id}`}
                                    className="webBtn mdBtn"
                                  >
                                    {localStorage.getItem("site_lang") === "eng"
                                      ? "View Details"
                                      : "Afficher les détails"}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col">
                          <div className="inner">
                            <div className="no_job">
                              <h4 className="text-center">
                                {isLoading
                                  ? localStorage.getItem("site_lang") === "eng"
                                    ? "Data Fetching..."
                                    : "Récupération de données..."
                                  : localStorage.getItem("site_lang") === "eng"
                                  ? "No Jobs Found"
                                  : "Aucun emploi trouvé"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default SavedJobs;
