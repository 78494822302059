import {
  FETCH_ALL_CANDIDATES,
  FETCH_ALL_CANDIDATES_SUCCESS,
  FETCH_ALL_CANDIDATES_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  //   isSearching: false,
  content: {},
  candidates: [],
  error: false,
  //   isFormProcessing: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_CANDIDATES:
      return {
        ...state,
        isLoading: true,
        content: {},
        candidates: [],
      };
    case FETCH_ALL_CANDIDATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: action.payload,
        candidates: action.payload.candidates,
      };
    case FETCH_ALL_CANDIDATES_FAILED:
      return {
        ...state,
        isLoading: false,
        content: {},
        candidates: [],
        error: action.payload,
      };
    default:
      return state;
  }
}
