import React, { useState, useEffect } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobs, saveJobAction } from "../../../../states/actions/fetchJobs";
import { fetchJobCategories } from "../../../../states/actions/fetchJobCategories";
import { fetchJobTypes } from "../../../../states/actions/fetchJobTypes";
import { fetchJobSubCategories } from "../../../../states/actions/fetchJobSubCategories";
import { fetchJobExperienceLevels } from "../../../../states/actions/fetchJobExperienceLevels";
import { fetchJobLocations } from "../../../../states/actions/fetchJobLocations";
import { ToastContainer } from "react-toastify";
import FormProcessingSpinner from "../../../common/FormProcessingSpinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TagsInput } from "react-tag-input-component";
import { API_UPLOADS_URL } from "../../../../constants/paths";
import LoadingScreen from "../../../common/LoadingScreen";
import { fetchSiteSettings } from "../../../../states/actions/fetchSiteSettings";
import { consoleLog } from "../../../../helpers/helpers";

const PostNewJob = () => {
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const [pushPopup, setPushPopup] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const data = useSelector((state) => state.fetchJobs.content);
  const jobCategories = useSelector(
    (state) => state.fetchJobCategories.categories
  );

  const jobTypes = useSelector((state) => state.fetchJobTypes.jobTypes);

  const jobSubCategories = useSelector(
    (state) => state.fetchJobSubCategories.subCategories
  );
  const jobExperienceLevels = useSelector(
    (state) => state.fetchJobExperienceLevels.experienceLevels
  );

  const jobLocations = useSelector(
    (state) => state.fetchJobLocations.locations
  );
  // consoleLog(jobLocations);
  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState("");
  const isFormProcessing = useSelector(
    (state) => state.fetchJobs.isFormProcessing
  );

  useEffect(() => {
    dispatch(fetchSiteSettings());
    dispatch(fetchJobs());
    dispatch(fetchJobCategories());
    dispatch(fetchJobTypes());
    dispatch(fetchJobExperienceLevels());
    dispatch(fetchJobLocations());
  }, []);

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm();

  const validateMaxSalary = (value) => {
    let minSalary = watch("minimum_salary");
    minSalary = Number(minSalary);
    value = Number(value);
    if (minSalary && value < minSalary) {
      return localStorage.getItem("site_lang") === "eng"
        ? "Max salary must be greater than min salary"
        : "Le salaire maximum doit être supérieur au salaire minimum";
    }
    return true;
  };

  const validateMaxWorkingHour = (value) => {
    let minHours = watch("min_working_hours");
    minHours = Number(minHours);
    value = Number(value);
    if (minHours && value < minHours) {
      return localStorage.getItem("site_lang") === "eng"
        ? "Max hours must be greater than min hours"
        : "Le nombre d'heures maximum doit être supérieur au nombre d'heures minimum";
    }
    return true;
  };

  const handleCategoryChange = (e) => {
    dispatch(fetchJobSubCategories(e.target.value));
  };

  const saveJob = (data) => {
    if (description === "") {
      setDescriptionError(
        localStorage.getItem("site_lang") === "eng"
          ? "Description is required"
          : "Une description est requise"
      );
      return false;
    }
    if (tags.length === 0) {
      setTagsError(
        localStorage.getItem("site_lang") === "eng"
          ? "Tags are required"
          : "Les balises sont obligatoires"
      );
      return false;
    }
    data.description = description;
    data.tags = tags;
    dispatch(saveJobAction(data));
  };

  const handleImgChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    trigger("company_image");
  };
  const handleSubmitForm = (e) => {
    if (description === "") {
      setDescriptionError(
        localStorage.getItem("site_lang") === "eng"
          ? "Description is required"
          : "Une description est requise"
      );
      return false;
    }
  };
  const removeError = () => {
    setDescriptionError(false);
  };

  return (
    <>
      {isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <HeaderLogged site_settings={siteSettings} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /{" "}
                        {localStorage.getItem("site_lang") === "eng"
                          ? "My Jobs"
                          : "Mes emplois"}{" "}
                      </span>{" "}
                      <em>
                        /{" "}
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Post New Job"
                          : "Publier un nouvel emploi"}
                      </em>
                    </h3>
                  </div>
                  <div className="dash_body">
                    <div className="dash_heading_sec">
                      <h2>
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Post New Job"
                          : "Publier un nouvel emploi"}
                      </h2>
                      <Link to="/employer/my-jobs">
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Back to page"
                          : "Retour à la page"}{" "}
                        &gt;&gt;
                      </Link>
                    </div>
                    <div className="dash_blk_box">
                      <form
                        action
                        method="post"
                        className="frmAjax"
                        id="frmTopic"
                        enctype="multipart/form-data"
                        onSubmit={handleSubmit(saveJob)}
                      >
                        <div className="formRow row">
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Title"
                                  : "Titre"}
                              </label>
                              <input
                                type="text"
                                name="title"
                                className="txtBox"
                                {...register("title", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Title is required"
                                      : "Le titre est requis",
                                })}
                              />
                              {errors.title && (
                                <span className="validation-error">
                                  {errors.title.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Category"
                                  : "Catégorie"}
                              </label>
                              <select
                                name="category"
                                id
                                className="txtBox"
                                {...register("category", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Category is required"
                                      : "La catégorie est obligatoire",
                                })}
                                onChange={handleCategoryChange}
                              >
                                <option value="" selected disabled>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Select Category"
                                    : "Sélectionnez une catégorie"}
                                </option>
                                {jobCategories?.map((category) => (
                                  <option value={category.id}>
                                    {localStorage.getItem("site_lang") === "eng"
                                      ? category.title
                                      : category.fr_title}
                                  </option>
                                ))}
                              </select>
                              {errors.category && (
                                <span className="validation-error">
                                  {errors.category.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Sub Category"
                                  : "Sous-catégorie"}
                              </label>
                              <select
                                name="sub_category"
                                id
                                className="txtBox"
                                {...register("sub_category", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Sub Category is required"
                                      : "La sous-catégorie est obligatoire",
                                })}
                              >
                                <option value="" selected disabled>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Select Sub Category"
                                    : "Sélectionnez une sous-catégorie"}
                                </option>
                                {jobSubCategories?.map((subCategory) => (
                                  <option value={subCategory.id}>
                                    {localStorage.getItem("site_lang") === "eng"
                                      ? subCategory.title
                                      : subCategory.fr_title}
                                  </option>
                                ))}
                              </select>
                              {errors.sub_category && (
                                <span className="validation-error">
                                  {errors.sub_category.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Job Type"
                                  : "Type d'emploi"}
                              </label>
                              <select
                                name="job_type"
                                id
                                className="txtBox"
                                {...register("job_type", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Job Type is required"
                                      : "Le type de travail est requis",
                                })}
                              >
                                <option value="" selected disabled>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Select Job Type"
                                    : "Sélectionnez le type de travail"}
                                </option>
                                {jobTypes?.map((jobType) => (
                                  <option value={jobType.id}>
                                    {localStorage.getItem("site_lang") === "eng"
                                      ? jobType.title
                                      : jobType.fr_title}
                                  </option>
                                ))}
                              </select>
                              {errors.job_type && (
                                <span className="validation-error">
                                  {errors.job_type.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Experience Level"
                                  : "Niveau d'expérience"}
                              </label>
                              <select
                                name="experience_level"
                                id
                                className="txtBox"
                                {...register("experience_level", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Experience Level is required"
                                      : "Le niveau d'expérience est requis",
                                })}
                              >
                                <option value="" selected disabled>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Select Experience Level"
                                    : "Sélectionnez le niveau d'expérience"}
                                </option>
                                {jobExperienceLevels?.map(
                                  (jobExperienceLevel) => (
                                    <option value={jobExperienceLevel.id}>
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? jobExperienceLevel.title
                                        : jobExperienceLevel.fr_title}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors.experience_level && (
                                <span className="validation-error">
                                  {errors.experience_level.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Job Office Type"
                                  : "Type de bureau d'emploi"}
                              </label>
                              <select
                                name="job_office_type"
                                id
                                className="txtBox"
                                {...register("job_office_type", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Job Location is required"
                                      : "Le lieu de travail est requis",
                                })}
                              >
                                <option value="" selected disabled>
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "Select Job Type"
                                    : "Sélectionnez le type de travail"}
                                </option>
                                {jobLocations?.map((jobLocation) => (
                                  <option value={jobLocation.id}>
                                    {localStorage.getItem("site_lang") === "eng"
                                      ? jobLocation.title
                                      : jobLocation.fr_title}
                                  </option>
                                ))}
                              </select>
                              {errors.job_office_type && (
                                <span className="validation-error">
                                  {errors.job_office_type.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="txtGrp tags">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Tags"
                                  : "Balises"}
                                *
                              </label>
                              <TagsInput
                                value={tags}
                                onChange={setTags}
                                name="tags"
                                placeHolder={
                                  localStorage.getItem("site_lang") === "eng"
                                    ? "Write a tag and press enter to write multiple tags"
                                    : "Écrivez une balise et appuyez sur Entrée pour écrire plusieurs balises"
                                }
                              />
                              {tagsError && (
                                <span className="validation-error">
                                  {tagsError}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="dash_heading_sec">
                              <h3>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Company Info"
                                  : "Informations sur l'entreprise"}
                              </h3>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Company Name"
                                  : "Nom de l'entreprise"}
                              </label>
                              <input
                                type="text"
                                name="company_name"
                                className="txtBox"
                                {...register("company_name", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Company Name is required"
                                      : "Le nom de l'entreprise est requis",
                                })}
                              />
                              {errors.company_name && (
                                <span className="validation-error">
                                  {errors.company_name.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Company Link"
                                  : "Lien d'entreprise"}
                              </label>
                              <input
                                type="text"
                                name="company_link"
                                className="txtBox"
                                {...register("company_link", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Company Link is required"
                                      : "Le lien de l'entreprise est requis",
                                })}
                              />
                              {errors.company_link && (
                                <span className="validation-error">
                                  {errors.company_link.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Location (city)"
                                  : "Localisation (ville)"}
                              </label>
                              <input
                                type="text"
                                name="city"
                                className="txtBox"
                                {...register("city", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Location is required"
                                      : "L'emplacement est requis",
                                })}
                              />
                              {errors.city && (
                                <span className="validation-error">
                                  {errors.city.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Zip Code"
                                  : "Code postal"}
                              </label>
                              <input
                                type="text"
                                name="zip_code"
                                className="txtBox"
                                {...register("zip_code", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Zip Code is required"
                                      : "Le code postal est requis",
                                })}
                              />
                              {errors.zip_code && (
                                <span className="validation-error">
                                  {errors.zip_code.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Company Image"
                                  : "Image de l'entreprise"}
                              </label>
                              <input
                                type="file"
                                name="company_image"
                                className="txtBox"
                                {...register("company_image", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Company Image is required"
                                      : "L'image de l'entreprise est requise",
                                })}
                                onChange={handleImgChange}
                              />
                              {errors.company_image && (
                                <span className="validation-error">
                                  {errors.company_image.message}
                                </span>
                              )}
                              <div className="company_image">
                                {imageUrl ? (
                                  <img src={imageUrl} alt="Preview" />
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="dash_heading_sec">
                              <h3>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Salary Information"
                                  : "Informations sur le salaire"}
                              </h3>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Minimum Salary"
                                  : "Salaire minimum"}
                              </label>
                              <input
                                type="number"
                                name="minimum_salary"
                                className="txtBox"
                                {...register("minimum_salary", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Minimum Salary is required"
                                      : "Un salaire minimum est requis",
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message:
                                      localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Please enter a valid number"
                                        : "Veuillez entrer un numéro valide",
                                  },
                                })}
                              />
                              {errors.minimum_salary && (
                                <span className="validation-error">
                                  {errors.minimum_salary.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Maximum Salary"
                                  : "Salaire maximum"}
                              </label>
                              <input
                                type="number"
                                name="maximum_salary"
                                className="txtBox"
                                {...register("maximum_salary", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Maximum Salary is required"
                                      : "Le salaire maximum est requis",
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message:
                                      localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Please enter a valid number"
                                        : "Veuillez entrer un numéro valide",
                                  },
                                  validate: validateMaxSalary,
                                })}
                              />
                              {errors.maximum_salary && (
                                <span className="validation-error">
                                  {errors.maximum_salary.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Min. Working Hours"
                                  : "Min. Heures de travail"}
                              </label>
                              <input
                                type="text"
                                name="min_working_hours"
                                className="txtBox"
                                {...register("min_working_hours", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Min. Working Hours is required"
                                      : "Min. Les heures de travail sont requises",
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message:
                                      localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Please enter a valid number"
                                        : "Veuillez entrer un numéro valide",
                                  },
                                })}
                              />
                              {errors.min_working_hours && (
                                <span className="validation-error">
                                  {errors.min_working_hours.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="txtGrp">
                              <label htmlFor className="move move_important">
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Max. Working Hours"
                                  : "Max. Heures de travail"}
                              </label>
                              <input
                                type="text"
                                name="max_working_hours"
                                className="txtBox"
                                {...register("max_working_hours", {
                                  required:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Max. Working Hours is required"
                                      : "Max. Les heures de travail sont requises",
                                  pattern: {
                                    value: /^[0-9]*$/,
                                    message:
                                      localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Please enter a valid number"
                                        : "Veuillez entrer un numéro valide",
                                  },
                                  validate: validateMaxWorkingHour,
                                })}
                              />
                              {errors.max_working_hours && (
                                <span className="validation-error">
                                  {errors.max_working_hours.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 ckEditor">
                            <div className="txtGrp">
                              <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDescription(data);
                                  removeError();
                                }}
                                config={{
                                  placeholder:
                                    localStorage.getItem("site_lang") === "eng"
                                      ? "Description..."
                                      : "Description...",
                                }}
                              />
                              {descriptionError && (
                                <span className="validation-error">
                                  {descriptionError}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="bTn formBtn text-center">
                          <button
                            type="submit"
                            onClick={handleSubmitForm}
                            className="webBtn icoBtn"
                            disabled={isFormProcessing}
                          >
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Submit"
                              : "Soumettre"}
                            <FormProcessingSpinner
                              isFormProcessing={isFormProcessing}
                            />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default PostNewJob;
