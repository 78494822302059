import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_FORGOT_PASSWORD_CONTENT,
  FETCH_FORGOT_PASSWORD_CONTENT_SUCCESS,
  FETCH_FORGOT_PASSWORD_CONTENT_FAILED,
  FORGOT_PASSWORD_LINK,
  FORGOT_PASSWORD_LINK_SUCCESS,
  FORGOT_PASSWORD_LINK_FAILED,
} from "./actionTypes";

export const fetchForgotPassword = () => (dispatch) => {
  dispatch({
    type: FETCH_FORGOT_PASSWORD_CONTENT,
    payload: null,
  });
  http
    .post(
      "forgot-password-content",
      helpers.doObjToFormData({ site_lang: localStorage.getItem("site_lang") })
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_FORGOT_PASSWORD_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FORGOT_PASSWORD_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const sendLink = (formData) => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD_LINK,
    payload: null,
  });
  http
    .post("auth/forgot_password", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      const siteLang = localStorage.getItem("site_lang");

      if (data.status) {
        const successMessage =
          siteLang === "eng"
            ? "We’ve emailed you a link to reset your password. If you don’t see it in your inbox, please check your spam or junk folder."
            : "Nous vous avons envoyé un lien pour réinitialiser votre mot de passe. Si vous ne le voyez pas dans votre boîte de réception, veuillez vérifier votre dossier spam ou courrier indésirable.";

        toast.success(successMessage, TOAST_SETTINGS);

        // toast.success(
        //   "We’ve emailed you a link to reset your password. If you don’t see it in your inbox, please check your spam or junk folder.",
        //   TOAST_SETTINGS
        // );
        dispatch({
          type: FORGOT_PASSWORD_LINK_SUCCESS,
          payload: data,
        });
      } else {
        if (!data.status) {
          if (data.validationErrors) {
            toast.error(
              <Text string={data.validationErrors} parse={true} />,
              TOAST_SETTINGS
            );
          } else if (data.notExist) {
            const errorMessage =
              siteLang === "eng"
                ? "Email does not exist."
                : "L'e-mail n'existe pas.";
            toast.error(errorMessage, TOAST_SETTINGS);
            // toast.error("Email not exits", TOAST_SETTINGS);
          }
          dispatch({
            type: FORGOT_PASSWORD_LINK_FAILED,
            payload: null,
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: FORGOT_PASSWORD_LINK_FAILED,
        payload: error,
      });
    });
};

// export const signout = () => {
//   localStorage.removeItem("authToken");
//   window.location.replace("/signin");
// };
