import React, { useState, useEffect } from "react";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import HeaderLogged from "../../../shared/HeaderLogged";
import { Link } from "react-router-dom";
import {
  fetchPaymentMethods,
  deletePaymentMethod,
} from "../../../../states/actions/paymentMethod";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const [pushPopup, setPushPopup] = useState(false);
  const data = useSelector((state) => state.paymentMethod.paymentMethods);
  const isLoading = useSelector((state) => state.paymentMethod.isLoading);
  const isDeleting = useSelector((state) => state.paymentMethod.isDeleting);

  // const TogglePush = () => {
  //   setPushPopup(!pushPopup);
  // }

  const handleDelete = (id) => {
    dispatch(deletePaymentMethod(id));
  };

  useEffect(() => {
    dispatch(fetchPaymentMethods());
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <HeaderLogged site_settings={null} />
          <main dashboard="">
            <section className="dash_outer">
              <div className="inner_dash">
                <div className="side_bar">
                  <EmployerSidebar />
                </div>
                <div className="content_area">
                  <div className="dash_header">
                    <h3>
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Dashboard"
                        : "Tableau de bord"}{" "}
                      <span>
                        /
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Payment Method"
                          : "Mode de paiement"}
                      </span>
                    </h3>
                    <div className="bTn">
                      <Link
                        to="/employer/add-payment-method"
                        className="webBtn"
                      >
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Add new payment method"
                          : "Ajouter un nouveau mode de paiement"}
                      </Link>
                    </div>
                  </div>
                  {isDeleting ? (
                    <div className="dash_blk_box text-center" role="alert">
                      {localStorage.getItem("site_lang") === "eng"
                        ? "Deleting..."
                        : "Suppression..."}
                    </div>
                  ) : (
                    <div className="dash_body">
                      <div className="dash_blk_box blockLst payment_tbl">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Bank Name"
                                  : "Nom de la banque"}
                              </th>
                              <th>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Account Title"
                                  : "Titre du compte"}
                              </th>
                              <th>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Account Number"
                                  : "Numéro de compte"}
                              </th>
                              <th>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Swift/Routing"
                                  : "Rapide/Routage"}{" "}
                                #
                              </th>
                              <th>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Status"
                                  : "Statut"}
                              </th>
                              <th>
                                {localStorage.getItem("site_lang") === "eng"
                                  ? "Actions"
                                  : "Actes"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length > 0 ? (
                              data.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.bank_name} </td>
                                  <td>{item.account_title}</td>
                                  <td>{item.account_number}</td>
                                  <td>{item.swift_no}</td>
                                  <td>
                                    <span
                                      className={`badge ${
                                        item.status == 1 ? "green" : ""
                                      }`}
                                    >
                                      {item.status == 1
                                        ? localStorage.getItem("site_lang") ===
                                          "eng"
                                          ? "Default"
                                          : "Défaut"
                                        : localStorage.getItem("site_lang") ===
                                          "eng"
                                        ? "Make Default"
                                        : "Par défaut"}
                                    </span>
                                  </td>
                                  <td className="dash_actions">
                                    <Link
                                      to={`/employer/edit-payment-method/${item.id}`}
                                      className="webBtn labelBtn blue-color"
                                    >
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Edit"
                                        : "Modifier"}
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            localStorage.getItem(
                                              "site_lang"
                                            ) === "eng"
                                              ? "Are you sure you wish to delete this payment method?"
                                              : "Êtes-vous sûr de vouloir supprimer ce mode de paiement ?"
                                          )
                                        )
                                          handleDelete(item.id);
                                      }}
                                      className="webBtn labelBtn red-color"
                                    >
                                      {localStorage.getItem("site_lang") ===
                                      "eng"
                                        ? "Delete"
                                        : "Supprimer"}
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={6} className="text-center">
                                  {localStorage.getItem("site_lang") === "eng"
                                    ? "No Record Found"
                                    : "Aucun enregistrement trouvé"}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default PaymentMethod;
