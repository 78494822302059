import React, { useEffect, useState } from "react";
import HeaderLogged from "../../../shared/HeaderLogged";
import EmployerSidebar from "../../../shared/Employer-Sidebar";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJobDetails } from "../../../../states/actions/fetchJobDetails";
import { fetchJobCategories } from "../../../../states/actions/fetchJobCategories";
import { fetchJobTypes } from "../../../../states/actions/fetchJobTypes";
import { fetchJobSubCategories } from "../../../../states/actions/fetchJobSubCategories";
import { fetchJobExperienceLevels } from "../../../../states/actions/fetchJobExperienceLevels";
import { fetchJobLocations } from "../../../../states/actions/fetchJobLocations";
import { updateJob } from "../../../../states/actions/employerJobs";
import { API_UPLOADS_URL } from "../../../../constants/paths";
import FormProcessingSpinner from "../../../common/FormProcessingSpinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TagsInput } from "react-tag-input-component";
import ImageControl from "../../../common/ImageControl";
import { fetchSiteSettings } from "../../../../states/actions/fetchSiteSettings";
import LoadingScreen from "../../../common/LoadingScreen";

const EditJob = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const jobDetails = useSelector(
    (state) => state.fetchJobDetails.jobDetails.job
  );
  const jobCategories = useSelector(
    (state) => state.fetchJobCategories.categories
  );
  const jobTypes = useSelector((state) => state.fetchJobTypes.jobTypes);
  const jobSubCategories = useSelector(
    (state) => state.fetchJobSubCategories.subCategories
  );
  const jobExperienceLevels = useSelector(
    (state) => state.fetchJobExperienceLevels.experienceLevels
  );
  const jobLocations = useSelector(
    (state) => state.fetchJobLocations.locations
  );
  const isFormProcessing = useSelector(
    (state) => state.employerJobs.isFormProcessing
  );
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [tags, setTags] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [tagsError, setTagsError] = useState("");
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: {} });

  const validateMaxSalary = (value) => {
    let minSalary = watch("min_salary");
    value = Number(value);
    minSalary = Number(minSalary);
    if (minSalary && value < minSalary) {
      return localStorage.getItem("site_lang") === "eng"
        ? "Max salary must be greater than min salary"
        : "Le salaire maximum doit être supérieur au salaire minimum";
    }
    return true;
  };

  const validateMaxWorkingHour = (value) => {
    let minHours = watch("min_working_hour");
    value = Number(value);
    minHours = Number(minHours);
    if (minHours && value < minHours) {
      return localStorage.getItem("site_lang") === "eng"
        ? "Max hours must be greater than min hours"
        : "Le nombre d'heures maximum doit être supérieur au nombre d'heures minimum";
    }
    return true;
  };

  useEffect(() => {
    dispatch(fetchSiteSettings());

    dispatch(fetchJobDetails(id));
    dispatch(fetchJobCategories());
    dispatch(fetchJobTypes());
    dispatch(fetchJobExperienceLevels());
    dispatch(fetchJobLocations());
  }, [id]);

  useEffect(() => {
    if (jobDetails?.job_cat) {
      setDescription(jobDetails?.description);
      if (jobDetails?.tags.split(",").length > 1) {
        const tagsArray = jobDetails?.tags.split(",");
        setTags(tagsArray);
      } else {
        setTags([jobDetails?.tags]);
      }
      dispatch(fetchJobSubCategories(jobDetails?.job_cat));
      for (const [key, value] of Object.entries(jobDetails)) {
        setValue(key, value);
      }
    }
  }, [jobDetails]);

  const handleCategoryChange = (e) => {
    dispatch(fetchJobSubCategories(e.target.value));
  };

  const saveJob = (data) => {
    if (description === "") {
      setDescriptionError("Description is required");
      return false;
    }
    if (tags.length === 0) {
      setTagsError("Tags are required");
      return false;
    }
    data.description = description;
    data.tags = tags;
    dispatch(updateJob(id, data));
  };

  const updateUploadImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageUrl(reader.result);
    };
  };

  return (
    <>
      <ToastContainer />
      <HeaderLogged site_settings={null} />
      <main dashboard="">
        <section className="dash_outer">
          <div className="inner_dash">
            <div className="side_bar">
              <EmployerSidebar />
            </div>
            <div className="content_area">
              <div className="dash_header">
                <h3>
                  {localStorage.getItem("site_lang") === "eng"
                    ? "Dashboard"
                    : "Tableau de bord"}{" "}
                  <span>
                    /{" "}
                    {localStorage.getItem("site_lang") === "eng"
                      ? "My Jobs"
                      : "Mes emplois"}{" "}
                  </span>{" "}
                  <em>
                    /{" "}
                    {localStorage.getItem("site_lang") === "eng"
                      ? "Edit Job Details"
                      : "Modifier les détails du travail"}
                  </em>
                </h3>
              </div>
              <div className="dash_body">
                <div className="dash_heading_sec">
                  <h2>
                    {localStorage.getItem("site_lang") === "eng"
                      ? "Edit Job Details"
                      : "Modifier les détails du travail"}
                  </h2>
                  <Link to="/employer/my-jobs">
                    {localStorage.getItem("site_lang") === "eng"
                      ? "Back to page"
                      : "Retour à la page"}{" "}
                    &gt;&gt;
                  </Link>
                </div>
                <div className="dash_blk_box">
                  <form
                    action
                    method="post"
                    className="frmAjax"
                    id="frmTopic"
                    enctype="multipart/form-data"
                    onSubmit={handleSubmit(saveJob)}
                  >
                    <div className="formRow row">
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Title"
                              : "Titre"}
                            *
                          </label>
                          <input
                            type="text"
                            name="title"
                            className="txtBox"
                            defaultValue={watch("title")}
                            {...register("title", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Title is required"
                                  : "Le titre est requis",
                            })}
                          />
                          {errors.title && (
                            <span className="validation-error">
                              {errors.title?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Category"
                              : "Catégorie"}
                            *
                          </label>
                          <select
                            name="job_cat"
                            className="txtBox"
                            defaultValue={watch("job_cat")}
                            {...register("job_cat", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Category is required"
                                  : "La catégorie est obligatoire",
                            })}
                            onChange={handleCategoryChange}
                          >
                            <option value="" selected disabled>
                              {localStorage.getItem("site_lang") === "eng"
                                ? "Select Category"
                                : "Sélectionnez une catégorie"}
                            </option>
                            {jobCategories?.map((category) => (
                              <option
                                value={category.id}
                                selected={category.id === jobDetails?.job_cat}
                              >
                                {localStorage.getItem("site_lang") === "eng"
                                  ? category.title
                                  : category.fr_title}
                              </option>
                            ))}
                          </select>
                          {errors.job_cat && (
                            <span className="validation-error">
                              {errors.job_cat?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Sub Category"
                              : "Sous-catégorie"}
                            *
                          </label>
                          <select
                            name="job_sub_cat"
                            className="txtBox"
                            defaultValue={watch("job_sub_cat")}
                            {...register("job_sub_cat", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Sub Category is required"
                                  : "La sous-catégorie est obligatoire",
                            })}
                          >
                            <option value="" selected disabled>
                              {localStorage.getItem("site_lang") === "eng"
                                ? "Select Sub Category"
                                : "Sélectionnez une sous-catégorie"}
                            </option>
                            {jobSubCategories?.map((subCategory) => (
                              <option
                                value={subCategory.id}
                                selected={
                                  subCategory.id === jobDetails?.job_sub_cat
                                }
                              >
                                {localStorage.getItem("site_lang") === "eng"
                                  ? subCategory.title
                                  : subCategory.fr_title}
                              </option>
                            ))}
                          </select>
                          {errors.job_sub_cat && (
                            <span className="validation-error">
                              {errors.job_sub_cat?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Job Type"
                              : "Type d'emploi"}
                            *
                          </label>
                          <select
                            name="job_type"
                            className="txtBox"
                            defaultValue={watch("job_type")}
                            {...register("job_type", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Job Type is required"
                                  : "Le type de travail est requis",
                            })}
                          >
                            <option value="" selected disabled>
                              {localStorage.getItem("site_lang") === "eng"
                                ? "Select Job Type"
                                : "Sélectionnez le type de travail"}
                            </option>
                            {jobTypes?.map((jobType) => (
                              <option
                                value={jobType.id}
                                selected={jobType.id === jobDetails?.job_type}
                              >
                                {localStorage.getItem("site_lang") === "eng"
                                  ? jobType.title
                                  : jobType.fr_title}
                              </option>
                            ))}
                          </select>
                          {errors.job_type && (
                            <span className="validation-error">
                              {errors.job_type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Experience Level"
                              : "Niveau d'expérience"}
                            *
                          </label>
                          <select
                            name="job_level"
                            className="txtBox"
                            defaultValue={watch("job_level")}
                            {...register("job_level", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Experience Level is required"
                                  : "Le niveau d'expérience est requis",
                            })}
                          >
                            <option value="" selected disabled>
                              {localStorage.getItem("site_lang") === "eng"
                                ? "Select Experience Level"
                                : "Sélectionnez le niveau d'expérience"}
                            </option>
                            {jobExperienceLevels?.map((jobExperienceLevel) => (
                              <option
                                value={jobExperienceLevel.id}
                                selected={
                                  jobExperienceLevel.id ===
                                  jobDetails?.job_level
                                }
                              >
                                {localStorage.getItem("site_lang") === "eng"
                                  ? jobExperienceLevel.title
                                  : jobExperienceLevel.fr_title}
                              </option>
                            ))}
                          </select>
                          {errors.job_level && (
                            <span className="validation-error">
                              {errors.job_level?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Job Office Type"
                              : "Type de bureau d'emploi"}
                            *
                          </label>
                          <select
                            name="job_office_type"
                            className="txtBox"
                            defaultValue={watch("job_office_type")}
                            {...register("job_office_type", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Job Location is required"
                                  : "Le lieu de travail est requis",
                            })}
                          >
                            <option value="" selected disabled>
                              {localStorage.getItem("site_lang") === "eng"
                                ? "Select Job Type"
                                : "Sélectionnez le type de travail"}
                            </option>
                            {jobLocations?.map((jobLocation) => (
                              <option
                                value={jobLocation.id}
                                selected={
                                  jobLocation.id === jobDetails?.job_office_type
                                }
                              >
                                {localStorage.getItem("site_lang") === "eng"
                                  ? jobLocation.title
                                  : jobLocation.fr_title}
                              </option>
                            ))}
                          </select>
                          {errors.job_office_type && (
                            <span className="validation-error">
                              {errors.job_office_type?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="txtGrp tags">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Tags"
                              : "Balises"}
                            *
                          </label>
                          <TagsInput
                            value={tags}
                            onChange={setTags}
                            name="tags"
                          />
                          {tagsError && (
                            <span className="validation-error">
                              {tagsError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="dash_heading_sec">
                          <h3>
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Company Info"
                              : "Informations sur l'entreprise"}
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Company Name"
                              : "Nom de l'entreprise"}
                            *
                          </label>
                          <input
                            type="text"
                            name="company_name"
                            className="txtBox"
                            defaultValue={jobDetails?.company_name}
                            {...register("company_name", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Company Name is required"
                                  : "Le nom de l'entreprise est requis",
                            })}
                          />
                          {errors.company_name && (
                            <span className="validation-error">
                              {errors.company_name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Company Link"
                              : "Lien d'entreprise"}
                            *
                          </label>
                          <input
                            type="text"
                            name="company_link"
                            className="txtBox"
                            defaultValue={jobDetails?.company_link}
                            {...register("company_link", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Company Link is required"
                                  : "Le lien de l'entreprise est requis",
                            })}
                          />
                          {errors.company_link && (
                            <span className="validation-error">
                              {errors.company_link?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Location (city)"
                              : "Localisation (ville)"}
                            *
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="txtBox"
                            defaultValue={jobDetails?.city}
                            {...register("city", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Location is required"
                                  : "L'emplacement est requis",
                            })}
                          />
                          {errors.city && (
                            <span className="validation-error">
                              {errors.city?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Company Image"
                              : "Image de l'entreprise"}
                          </label>
                          <input
                            type="file"
                            name="company_logo"
                            className="txtBox"
                            defaultValue={watch("company_logo")}
                            onChange={(e) => {
                              setValue("company_logo", e.target.files[0]);
                              updateUploadImage(e);
                            }}
                          />
                          <div className="company_image">
                            {imageUrl ? (
                              <img src={imageUrl} alt="Preview" />
                            ) : (
                              jobDetails?.company_logo && (
                                <ImageControl
                                  isThumb={true}
                                  folder="jobs"
                                  src={jobDetails?.company_logo}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="dash_heading_sec">
                          <h3>
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Salary Information"
                              : "Informations sur le salaire"}
                          </h3>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Minimum Salary"
                              : "Salaire minimum"}
                            *
                          </label>
                          <input
                            type="number"
                            name="min_salary"
                            className="txtBox"
                            defaultValue={watch("min_salary")}
                            {...register("min_salary", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Minimum Salary is required"
                                  : "Un salaire minimum est requis",
                              pattern: {
                                value: /^[0-9]*$/,
                                message:
                                  localStorage.getItem("site_lang") === "eng"
                                    ? "Please enter a valid number"
                                    : "Veuillez entrer un numéro valide",
                              },
                            })}
                          />
                          {errors.min_salary && (
                            <span className="validation-error">
                              {errors.min_salary?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Maximum Salary"
                              : "Salaire maximum"}
                            *
                          </label>
                          <input
                            type="number"
                            name="max_salary"
                            className="txtBox"
                            defaultValue={watch("max_salary")}
                            {...register("max_salary", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Maximum Salary is required"
                                  : "Le salaire maximum est requis",
                              pattern: {
                                value: /^[0-9]*$/,
                                message:
                                  localStorage.getItem("site_lang") === "eng"
                                    ? "Please enter a valid number"
                                    : "Veuillez entrer un numéro valide",
                              },
                              validate: validateMaxSalary,
                            })}
                          />
                          {errors.max_salary && (
                            <span className="validation-error">
                              {errors.max_salary?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Min. Working Hours"
                              : "Min. Heures de travail"}
                            *
                          </label>
                          <input
                            type="number"
                            name="min_working_hour"
                            className="txtBox"
                            defaultValue={watch("min_working_hour")}
                            {...register("min_working_hour", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Min. Working Hours is required"
                                  : "Min. Les heures de travail sont requises",
                              pattern: {
                                value: /^[0-9]*$/,
                                message:
                                  localStorage.getItem("site_lang") === "eng"
                                    ? "Please enter a valid number"
                                    : "Veuillez entrer un numéro valide",
                              },
                            })}
                          />
                          {errors.min_working_hour && (
                            <span className="validation-error">
                              {errors.min_working_hour?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            {localStorage.getItem("site_lang") === "eng"
                              ? "Max. Working Hours"
                              : "Max. Heures de travail"}
                            *
                          </label>
                          <input
                            type="number"
                            name="max_working_hour"
                            className="txtBox"
                            defaultValue={watch("max_working_hour")}
                            {...register("max_working_hour", {
                              required:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Max. Working Hours is required"
                                  : "Max. Les heures de travail sont requises",
                              pattern: {
                                value: /^[0-9]*$/,
                                message:
                                  localStorage.getItem("site_lang") === "eng"
                                    ? "Please enter a valid number"
                                    : "Veuillez entrer un numéro valide",
                              },
                              validate: validateMaxWorkingHour,
                            })}
                          />
                          {errors.max_working_hour && (
                            <span className="validation-error">
                              {errors.max_working_hour?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 ckEditor">
                        {/* <div className="txtGrp">
                          <label htmlFor className="move move_important">
                            Description*
                          </label>
                          <textarea
                            name="description"
                            id
                            className="txtBox"
                            defaultValue={watch("description")}
                            {...register("description", {
                              required: "Description is required",
                            })}
                          />
                          {errors.description && (
                            <span className="validation-error">
                              {errors.description?.message}
                            </span>
                          )}
                        </div> */}
                        <div className="txtGrp">
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescription(data);
                            }}
                            config={{
                              placeholder:
                                localStorage.getItem("site_lang") === "eng"
                                  ? "Description..."
                                  : "Description...",
                            }}
                          />
                          {descriptionError && (
                            <span className="validation-error">
                              {descriptionError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bTn formBtn text-center">
                      <button
                        type="submit"
                        className="webBtn icoBtn"
                        disabled={isFormProcessing}
                      >
                        {localStorage.getItem("site_lang") === "eng"
                          ? "Update"
                          : "Mise à jour"}
                        <FormProcessingSpinner
                          isFormProcessing={isFormProcessing}
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditJob;
