import http from "../../helpers/http";
import * as helpers from "../../helpers/helpers";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  DELETE_NOTIFICATIONS,
  DELETE_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATIONS_FAILED,
} from "./actionTypes";

export const fetchAllNotifications = () => (dispatch) => {
  dispatch({
    type: FETCH_NOTIFICATIONS,
    payload: null,
  });
  http
    .post(
      "notifications",
      helpers.doObjToFormData({
        authToken: localStorage.getItem("authToken"),
      })
    )
    .then(({ data }) => {
      console.log(data, "data");
      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAILED,
        payload: error,
      });
    });
};

export const deleteNotifications = () => (dispatch) => {
  dispatch({
    type: DELETE_NOTIFICATIONS,
    payload: null,
  });
  http
    .post(
      "clear-notifications",
      helpers.doObjToFormData({
        authToken: localStorage.getItem("authToken"),
      })
    )
    .then(({ data }) => {
      if (data.status) {
        toast.success(
          "Successfully cleared all notifications!",
          TOAST_SETTINGS
        );
        dispatch({
          type: DELETE_NOTIFICATIONS_SUCCESS,
          payload: data,
        });
      } else {
        toast.error("Something went wrong, please try again!", TOAST_SETTINGS);
        dispatch({
          type: DELETE_NOTIFICATIONS_FAILED,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DELETE_NOTIFICATIONS_FAILED,
        payload: error,
      });
    });
};
