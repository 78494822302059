import React from "react";
import { Link } from "react-router-dom";

const JobPush = ({ pushPopup, togglePush, noOfPush, handlePush }) => {
  return (
    <section
      className={
        pushPopup
          ? "popup small-popup push_popup active"
          : "popup small-popup push_popup"
      }
    >
      <div className="tableDv">
        <div className="tableCell">
          <div className="_inner">
            <div className="crosBtn" onClick={togglePush}></div>
            <h4>
              {localStorage.getItem("site_lang") === "eng"
                ? "Push your job on top"
                : "Mettez votre travail au premier plan"}
            </h4>
            <p>
              {localStorage.getItem("site_lang") === "eng"
                ? "According to your"
                : "Selon votre"}{" "}
              <strong>
                {localStorage.getItem("site_lang") === "eng"
                  ? "subscription plan"
                  : "plan d'abonnement"}
              </strong>{" "}
              {localStorage.getItem("site_lang") === "eng"
                ? "you can push your job on top"
                : "vous pouvez mettre votre travail au premier plan"}{" "}
              <strong>
                {noOfPush}
                {localStorage.getItem("site_lang") === "eng" ? "time" : "temps"}
              </strong>
              .
            </p>
            <br />
            <div className="text-center">
              <Link onClick={handlePush} className="webBtn">
                {localStorage.getItem("site_lang") === "eng"
                  ? "Push Job"
                  : "Pousser le travail"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobPush;
