import {
  FETCH_ALL_MEMBERS,
  FETCH_ALL_MEMBERS_SUCCESS,
  FETCH_ALL_MEMBERS_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isFetching: false,
  content: [],
  error: null,
  site_settings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_MEMBERS:
      return {
        ...state,
        isFetching: true,
        content: [],
      };
    case FETCH_ALL_MEMBERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        content: action.payload,
        site_settings: action.payload.site_settings,
      };
    case FETCH_ALL_MEMBERS_FAILED:
      return {
        ...state,
        isFetching: false,
        content: [],
        error: action.payload,
      };
    default:
      return state;
  }
}
